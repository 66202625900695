import { consentAgeConfig } from '../config/consentAge';

export function getConsentAgeByLocation({ location: { country } }) {
    if (typeof country === 'undefined') {
        throw new TypeError('location.country argument is undefined');
    }

    let consentAge = consentAgeConfig[country];

    if (!consentAge) {
        consentAge = consentAgeConfig.default;
    }

    return consentAge.consentAge;
}
